import "./App.css";
import Header from "./Components/Header/Header";
import TypedText from "./Components/TypedText/TypedText";

function App() {
  return (
    <div className="App">
      <Header />
      <TypedText />
    </div>
  );
}

export default App;
