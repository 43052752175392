import Typed from "typed.js";
import { useEffect, useRef } from "react";

export default function TypedText() {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "This is Prashanth Sobbani",
        "I'm a Software Engineer",
        "Who loves to code !!!",
        "HTML, CSS, JavaScript, React JS, Node JS ...",
      ],
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 200,
      backDelay: 100,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div
      style={{
        fontFamily: "monospace",
        background: "black",
        border: "1px solid yellow",
      }}
    >
      <h1 style={{ color: "white", fontWeight: "bold" }}>Hello World!</h1>
      <span
        style={{
          color: "white",
          fontStyle: "italic",
          fontSize: "25px",
          fontWeight: "100",
        }}
        ref={el}
      ></span>
    </div>
  );
}
